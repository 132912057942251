import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const TheBoat = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">The Boat</div>
      <img
        style={{ marginTop: "2%" }}
        className="projectPageImg"
        src={IMG("theBoatImg.png")}
      ></img>
      <div className="projectPageBody">
        The Boat is an interactive audio/visual web application that tells the
        true story of an incident that occurred in my family approximately
        twenty years ago. It explores the interplay of memory distortion,
        emotion, perspective, and family dynamics. Each of the story's six
        chapters are retold by the four different people who experienced the
        event: my mother, father, sister, and myself.
      </div>
      <a
        // style={{ marginLeft: "15%" }}
        className="boatLink"
        target="_blank"
        href="https://theboat.netlify.app/"
      >
        Click here to experience the story
      </a>
      <div style={{ marginTop: "5%" }} className="projectPageBody"></div>
      <CopyrightFooter />
    </div>
  );
};

export default TheBoat;
