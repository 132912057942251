import React from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import timeLoop from "./timeLoop";
import { withResizeDetector } from "react-resize-detector";

const shader = Shaders.create({
  lavalamp: {
    frag: GLSL`
      precision mediump float;

      uniform vec2 u_resolution;
      uniform float u_time;


      #define SHOW_NOISE 0
#define SRGB 0
// 0: Addition, 1: Screen, 2: Overlay, 3: Soft Light, 4: Lighten-Only
#define BLEND_MODE 0
#define SPEED 2.
#define INTENSITY.75
// What gray level noise should tend to.
#define MEAN 0.
// Controls the contrast/variance of noise.
#define VARIANCE.5

float gaussian(float z,float u,float o){
    return(1./(o*sqrt(2.*3.1415)))*exp(-(((z-u)*(z-u))/(2.*(o*o))));
}

// cosine based palette, 4 vec3 params from IQ
vec3 palette(in float t,in vec3 a,in vec3 b,in vec3 c,in vec3 d)
{
    return a+b*cos(6.28318*(c*t+d));
}

void main(){
    vec2 coord=gl_FragCoord.xy/u_resolution.xy;
    vec2 ps=vec2(1.)/u_resolution.xy;
    vec2 uv=coord*ps;
    vec4 noiseCol=pow(vec4(.1,.1,.1,1.),vec4(2.));
    
    float t=u_time*float(SPEED);
    float seed=dot(uv,vec2(12.9898,78.233));
    float noise=fract(sin(seed)*43758.5453+t);
    noise=gaussian(noise,float(MEAN),float(VARIANCE)*float(VARIANCE));
    float w=float(INTENSITY);
    vec3 grain=vec3(noise)*(1.-noiseCol.rgb);
    
    noiseCol.rgb+=grain*w;
    
    // IQ color gradient function
    vec3 colGrad=palette(coord.x,vec3(.75,.5,.5),vec3(.5,.15,.5),vec3(2.,1.,0.),vec3(0.,1.,.8667));
    
    noiseCol=pow(noiseCol,vec4(.2/3.2));
    
    // float t=u_time*.04;
    float pal_time=u_time*.04;
    
    float color=2.;
    color+=sin(coord.x*50.+cos(u_time/20.+coord.y*5.*sin(coord.x*5.+u_time/20.))*2.6);
    color+=cos(coord.x*20.+sin((u_time/10.)+coord.y*5.*cos(coord.x*5.+u_time/20.))*.4);
    color+=sin(coord.x*30.+cos((u_time/40.)+coord.y/5.+sin(coord.x*5.+u_time/20.))*2.);
    color+=cos(coord.x*10.5+sin(u_time-coord.y*5.+cos(coord.x*5.+u_time/2.))*2.);
    
    color-=1.2;
    gl_FragColor=vec4(colGrad,1.);
    
    gl_FragColor*=((vec4(vec3(color+coord.y,color+coord.x,coord.x+coord.y),1.))*noiseCol);
}
          `,
  },
});

const LavaLamp = ({ time }) => {
  return (
    <Node
      shader={shader.lavalamp}
      uniforms={{ u_time: time / 1000, u_resolution: [1920, 1920] }}
    ></Node>
  );
};

const InkInWaterLoop = timeLoop(LavaLamp);

const LavaLand_HomePage = ({ width, height }) => {
  return (
    <>
      <Surface width={window.innerWidth} height={window.innerHeight}>
        <InkInWaterLoop />
      </Surface>
      {`${width}${height}`}
    </>
  );
};

export default withResizeDetector(LavaLand_HomePage);
