import React from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import timeLoop from "./timeLoop";
import { useMediaQuery } from "react-responsive";

const shader = Shaders.create({
  raycast: {
    frag: GLSL`
    precision mediump float;
    uniform vec2 u_resolution;
    uniform float u_time;

    const int steps=128;
    const float maxDist=2.;
    const float PI=3.141592658;

   
vec3 cosPalette(float t,vec3 a,vec3 b,vec3 c,vec3 d)
{
    return a+b*cos(6.28318*(c*t+d));
}

float smin(float a,float b,float k)
{
    float h=clamp(.5+.5*(b-a)/k,0.,1.);
    return mix(b,a,h)-k*h*(1.-h);
}

vec3 hsv2rgb(vec3 c)
{
    vec4 K=vec4(1.,2./3.,1./3.,3.);
    vec3 p=abs(fract(c.xxx+K.xyz)*6.-K.www);
    return c.z*mix(K.xxx,clamp(p-K.xxx,0.,1.),c.y);
}


float pModPolar(inout vec2 p,float repetitions){
    float angle=2.*PI/repetitions;
    float a=atan(p.y,p.x)+angle/2.;
    float r=length(p);
    float c=floor(a/angle);
    a=mod(a,angle)-angle/2.;
    p=vec2(cos(a),sin(a))*r;
    if(abs(c)>=(repetitions/2.))c=abs(c);
    return c;
}

vec2 pModMirror2(inout vec2 p,vec2 size){
    vec2 halfsize=size*.5;
    vec2 c=floor((p+halfsize)/size);
    p=mod(p+halfsize,size)-halfsize;
    p*=mod(c,vec2(2.))*2.-vec2(1.);
    return c;
}

float sphere(vec3 pos,float rad){
    
    return length(pos)-rad;
}

float sdCross(in vec2 p,in vec2 b,float r)
{
    p=abs(p);p=(p.y>p.x)?p.yx:p.xy;
    
    vec2 q=p-b;
    float k=max(q.y,q.x);
    vec2 w=(k>0.)?q:vec2(b.y-p.x,-k);
    return sign(k)*length(max(w,0.))+r;
}

float sdEquilateralTriangle(in vec2 p)
{
    const float k=sqrt(3.);
    
    p.x=abs(p.x)-1.;
    p.y=p.y+1./k;
    if(p.x+k*p.y>0.)p=vec2(p.x-k*p.y,-k*p.x-p.y)/2.;
    p.x-=clamp(p.x,-2.,0.);
    return-length(p)*sign(p.y);
}

vec3 lookAt(vec2 uv,vec3 camOrigin,vec3 camTarget){
    vec3 zAxis=normalize(camTarget-camOrigin);
    vec3 up=vec3(0,1,0);
    vec3 xAxis=normalize(cross(up,zAxis));
    vec3 yAxis=normalize(cross(zAxis,xAxis));
    
    float fov=2.;
    
    vec3 dir=(normalize(uv.x*xAxis+uv.y*yAxis+zAxis*fov));
    
    return dir;
}

float scene(vec3 pos){
    vec3 modSpace=vec3(1.);
    pos=mod(pos,modSpace)-.5;
    float s=sphere(pos,.2);
    pModMirror2(pos.xy,vec2(.1,.8));
    float s2=sphere(pos-sin(u_time)/2.5,.02);
    float cross=sdCross(pos.xy,vec2(.2),.03);
    return smin(s2,cross,.5);
}

vec4 trace(vec3 camOrigin,vec3 dir){
    
    vec3 ray=camOrigin;
    float totalDist=0.;
    float dist;
    
    for(int i=0;i<steps;i++){
        dist=scene(ray);
        totalDist+=dist;
        if(dist<.001){
            
        return vec4(cosPalette(totalDist,vec3(.5,.5,.5),
        vec3(.5,.5,.5),vec3(1.,1.,1.),vec3(.30,.20,.20)),1.);
        }
        
        ray+=dist*dir;
    }
    
    return vec4((sin(u_time*.8)),.3,.9,1.);
}

void main(){
    vec2 uv=gl_FragCoord.xy/u_resolution.xy;
    uv=(uv*2.)-vec2(1.);
    
    uv.x*=u_resolution.x/u_resolution.y;
    
    vec3 camOrigin=vec3(0.,0.,-1.);
    vec3 rayOrigin=vec3(uv+camOrigin.xy,camOrigin.z+1.);
    
    vec3 camTarget=vec3(sin(u_time/2.),0.,sin(u_time));
    
    vec3 dir=lookAt(uv,camOrigin,camTarget);
        
    vec4 color=trace(camOrigin,dir);
    
    gl_FragColor=color;
}`,
  },
});

const Raycast = ({ time }) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:940px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let canvasWidth, canvasHeight;

  if (isMobileDevice) {
    canvasWidth = 350;
    canvasHeight = 150;
  } else if (isBigScreen) {
    canvasWidth = 1500;
    canvasHeight = 1000;
  } else {
    canvasWidth = 600;
    canvasHeight = 200;
  }
  return (
    <Node
      shader={shader.raycast}
      uniforms={{
        u_time: time / 1000,
        u_resolution: [canvasWidth, canvasHeight],
      }}
    ></Node>
  );
};

const RaycastLoop = timeLoop(Raycast);

const RaycastShader = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let canvasWidth, canvasHeight;

  if (isMobileDevice) {
    canvasWidth = 300;
    canvasHeight = 100;
  } else if (isBigScreen) {
    canvasWidth = 1500;
    canvasHeight = 1000;
  } else {
    canvasWidth = 600;
    canvasHeight = 200;
  }

  return (
    <>
      <Surface width={canvasWidth} height={canvasHeight}>
        <RaycastLoop />
      </Surface>
    </>
  );
};

export default RaycastShader;
