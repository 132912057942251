import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const CollectiveUnbelonging = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Collective Unbelonging</div>
      <div className="projectPageBody">
        Collective Unbelonging is an interactive installation that visualizes
        the experience of impostor syndrome.
      </div>
      <div className="player-wrapper-collectiveUnbelonging">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/306086096"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <div className="projectPageBody">
        Impostor syndrome is the feeling that you do not belong, that you are an
        outcast who has only become as successful as you are through luck. Those
        suffering from impostor syndrome feel as though they have a facade of
        competence masking their true identity and fear that facade being
        discovered by their peers. While over 70% of people experience feelings
        of impostor syndrome, it is infrequently discussed. The very notion of
        admitting to having these feelings risks exposing oneself as an
        impostor, an outcast.{" "}
      </div>
      <div className="projectPageBody">
        This installation visualizes the impostor phenomenon in a humorous and
        strange manner so that the topic becomes easier to discuss, and less of
        a taboo subject. Participants are transformed into 'Bigfoot' characters
        that, upon entering the canvas, are detected by a backdrop of watching,
        judging eyes. The installation is set to the sound of a public mall
        atrium space to enhance the anxious and surveilled experience.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default CollectiveUnbelonging;
