import React from "react";
import { useMediaQuery } from "react-responsive";

import { Player, ControlBar, Shortcut } from "video-react";

import RaycastShader from "../components/Raycast";
import InkInWaterShader from "../components/InkInWater";
import TwistedToroidShader from "../components/TwistedToroid";
import CopyrightFooter from "../components/CopyrightFooter";

const VID = (vidName) => {
  return require(`../assets/images/sketches/${vidName}`);
};

const Sketches = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let vidWidth, vidMargBottom, containerMargTop;

  if (isMobileDevice) {
    vidWidth = 300;
    vidMargBottom = "5%";
    containerMargTop = "6%";
  } else if (isBigScreen) {
    vidWidth = 1500;
    vidMargBottom = "2%";
    containerMargTop = "2.5%";
  } else {
    vidWidth = 600;
    vidMargBottom = "2%";
    containerMargTop = "2.5%";
  }

  const sketchesDiv = {
    marginTop: containerMargTop,
  };

  const sketch = {
    display: "inline-block",
    transform: "translate(-50%, 0%)",
    marginLeft: "50%",
    marginBottom: vidMargBottom,
  };

  return (
    <div className="page">
      <div style={sketchesDiv}>
        <div style={sketch}>
          <TwistedToroidShader />
        </div>
        <div style={sketch}>
          <RaycastShader />
        </div>
        <div style={sketch}>
          <InkInWaterShader />
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("Experiment_COCO_small.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>

        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("circleCV.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("ribbonCV.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("N_animation.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("noiseBlob.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("interactiveRocket.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
        <div style={sketch}>
          <Player
            volume={0}
            muted={true}
            playing={true}
            autoPlay={true}
            fluid={false}
            controls={false}
            loop={true}
            width={vidWidth}
            playsInline={true}
            src={VID("spiral2D.mp4")}
          >
            <ControlBar disableCompletely={true}></ControlBar>
            <Shortcut clickable={false} />
          </Player>
        </div>
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default Sketches;
