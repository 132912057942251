import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
    return require(`../assets/images/${imgName}`);
  };

const Versace = () => {
    return (
        <div className="page">
            <div className="projectPageTitle">Versace Shanghai Popup</div>
            <div className="projectPageBody">
                The Versace Shanghai popup was a temporary interactive installation in Shanghai's Plaza 66 shopping mall.
                In the style of Versace's sea-themed Spring-Summer 2021 Collection, we built a shell-shaped structure decorated with intricate coral cladding and a giant Medusa sculpture. <br/><br/>A custom interactive LED floor brings the pop-up to life with swirling water and sea creatures that react to, and follow the footsteps of visitors.

            </div>
            <div className="player-wrapper-versace">
            <ReactPlayer
                className="react-player"
                url="https://vimeo.com/555945069"
                width="100%"
                height="100%"
                controls="true"
            />
            </div>
            <img className="projectPageImg"
                style={{ marginBottom: "2%" }}
                src={IMG("versace/versace_hero.png")}>
            </img>
            <img className="projectPageImg"
                style={{ marginBottom: "2%" }}
                src={IMG("versace/versace_ground.jpg")}>
            </img>
            <img className="projectPageImg"
                style={{ marginBottom: "2%" }}
                src={IMG("versace/versace_bags.jpg")}>
            </img>
            <img className="projectPageImg"
                src={IMG("versace/versace_above.jpg")}>
            </img>
            <CopyrightFooter />
        </div>
    );
};

export default Versace;