import React from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import timeLoop from "./timeLoop";
import { useMediaQuery } from "react-responsive";

const shader = Shaders.create({
  inkInWater: {
    frag: GLSL`
        precision mediump float;

        uniform vec2 u_resolution;
        uniform float u_time;

        const int AMOUNT=12;

    void main(){
    vec2 coord=17.*(gl_FragCoord.xy-u_resolution/2.)/min(u_resolution.y,u_resolution.x);
    
    float len;
    
    for(int i=0;i<AMOUNT;i++){
        len=length(vec2(coord.x,coord.y));
        
        coord.x=coord.x-cos(coord.y+sin(len))+cos(u_time/7.);
        coord.y=coord.y+sin(coord.x+cos(len))+sin(u_time/10.);
        
    }
    gl_FragColor=vec4(cos(len),cos(len*1.4),cos(len*1.3),1.);
}
        `,
  },
});

const InkInWater = ({ time }) => {
  return (
    <Node
      shader={shader.inkInWater}
      uniforms={{ u_time: time / 1000, u_resolution: [1920, 1920] }}
    ></Node>
  );
};

const InkInWaterLoop = timeLoop(InkInWater);

const InkInWaterShader = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let canvasWidth, canvasHeight;

  if (isMobileDevice) {
    canvasWidth = 300;
    canvasHeight = 150;
  } else if (isBigScreen) {
    canvasWidth = 1500;
    canvasHeight = 1000;
  } else {
    canvasWidth = 600;
    canvasHeight = 400;
  }

  return (
    <Surface width={canvasWidth} height={canvasHeight}>
      <InkInWaterLoop />
    </Surface>
  );
};

export default InkInWaterShader;
