import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
    return require(`../assets/images/${imgName}`);
  };


const LVNBA = () => {
    return (
        <div className="page">
            <div className="projectPageTitle">Louis Vuitton x NBA</div>
            <div className="projectPageBody">
                We took over Madison Square Garden for an exclusive virtual shopping experience showcasing the LVxNBA capsule collection.
                Users entered the microsite through the drone footage we shot of the iconic stadium interior.
            </div>
            <div className="player-wrapper-versace">
            <ReactPlayer
                className="react-player"
                url="https://vimeo.com/555980401"
                width="100%"
                height="100%"
                controls="true"
            />
            </div>
            <div className="projectPageBody">360 navigation of an LV-branded Madison Square Garden allowed users to explore the LVxNBA products in a truly novel way. Each product was uniquely staged on the legendary MSG court, giving users the feeling of a private shopping experience at the world's most famous arena.
            </div>
            <div className="player-wrapper-versace">
            <ReactPlayer
                className="react-player"
                url="https://vimeo.com/555984080"
                width="100%"
                height="100%"
                controls="true"
            />
            </div>
            <img style={{ marginBottom: "2%" }} 
                className="projectPageImg" 
                src={IMG("lvxnba/lv_hero.png")}></img>
            <img style={{ marginBottom: "2%" }} 
                className="projectPageImg" 
                src={IMG("lvxnba/lv_court.png")}></img>
            <CopyrightFooter />
        </div>
    );
};

export default LVNBA;