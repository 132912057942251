import React from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import timeLoop from "./timeLoop";
import { useMediaQuery } from "react-responsive";

const shader = Shaders.create({
  twistedToroid: {
    frag: GLSL`
    
    precision mediump float;

    uniform vec2 u_resolution;
    uniform float u_time;

    void main(){
    vec2 uv=(gl_FragCoord.xy-.5*u_resolution.xy)/u_resolution.y;
    float t=u_time*.2;
    
    vec3 ro=vec3(0.,0.,-1.);
    
    vec3 lookat=vec3(0.);
    float zoom=mix(.02,.4,sin(t)*.5+.5);
    
    vec3 f=normalize(lookat-ro),
    
    r=normalize(cross(vec3(0.,1.,0.),f)),
    u=cross(f,r),
    c=ro+f*zoom,
    i=c+uv.x*r+uv.y*u,
    rd=normalize(i-ro);
    
    float dS,dO;
    vec3 p;
    
    for(int i=0;i<100;i++){
        p=ro+rd*dO;
        
        dS=-(length(vec2(length(p.xz)-1.,p.y))-.75);
        if(dS<.001)break;
        dO+=dS;
    }
    
    vec3 col=vec3(0.,.9882,.9373);
    if(dS<.001){
        float x=atan(p.x,p.z)+t*-.5;
        float y=atan(length(p.xz)-1.,p.y);
        float bands=sin(y*10.+x*30.);
        float ripples=sin((x*10.-y*30.)*3.)*.5+.5;
        float waves=sin(x*2.-y*6.+t*20.);
        
        float b1=smoothstep(-.2,.2,bands);
        float b2=smoothstep(-.2,.2,bands-.5);
        
        float m=b1*(1.-b2);
        m+=max(m,ripples*b2*max(0.,waves));
        m+=max(0.,waves*.3*b2);
        col+=mix(m,1.-m,smoothstep(-.3,.3,sin(x+t)));
    }
    
    gl_FragColor=vec4(sin(col-(sin(u_time*.05))),1.);
}
    `,
  },
});

const TwistedToroid = ({ time }) => {
  return (
    <Node
      shader={shader.twistedToroid}
      uniforms={{ u_time: time / 1000, u_resolution: [1080, 1080] }}
    ></Node>
  );
};

const TwistedToroidLoop = timeLoop(TwistedToroid);

const TwistedToroidShader = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let canvasWidth, canvasHeight;

  if (isMobileDevice) {
    canvasWidth = 300;
    canvasHeight = 150;
  } else if (isBigScreen) {
    canvasWidth = 1500;
    canvasHeight = 1000;
  } else {
    canvasWidth = 600;
    canvasHeight = 400;
  }

  return (
    <Surface width={canvasWidth} height={canvasHeight}>
      <TwistedToroidLoop />
    </Surface>
  );
};

export default TwistedToroidShader;
