import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const Nobu = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Nobu Downtown Digital Pond</div>
      <img
        style={{ marginTop: "2%" }}
        className="projectPageImg"
        src={IMG("nobu_hero.png")}
      ></img>
      <div className="projectPageBody">
        Nobu moved it's flagship restaurant from the original Tribeca location
        to New York City's Financial District in the spring of 2017. The LAB at
        Rockwell Group was approached to create a digital installation for the
        new space that needed to be elegant, modern, and subtle.
      </div>
      <div className="projectPageBody">
        We devised an LED pool that sits underneath the staircase, next to the
        host stand. We designed a custom looping animation to play on the LED.
        Taking inspiration from the original Nobu restaurant architectural
        language, the meditative animation consists of sakura flowers slowly
        floating in a pool of water and black river rocks.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default Nobu;
