import React from "react";
import { Shaders, Node, GLSL } from "gl-react";
import { Surface } from "gl-react-dom";
import timeLoop from "./timeLoop";
import { withResizeDetector } from "react-resize-detector";

const shader = Shaders.create({
  inkInWater: {
    frag: GLSL`
          precision mediump float;
  
          uniform vec2 u_resolution;
          uniform float u_time;
          
          const int AMOUNT=12;
          
          void main(){
              vec2 coord=17.*(gl_FragCoord.xy-u_resolution/2.)/min(u_resolution.y,u_resolution.x);
              
              float len;
              
              for(int i=0;i<AMOUNT;i++){
                  len=length(vec2(coord.x,coord.y));
                  coord.x=coord.x-cos(coord.y+sin(len))+cos(u_time/7.);
                  
                  coord.x=coord.x-sin(coord.y+sin(len))+cos(u_time/7.);
          
                  coord.y=coord.y+sin(coord.x+cos(len))+sin(u_time/7.);
                  
              }
              gl_FragColor=vec4(cos(len*.1),cos(len*.32),cos(len*.67),1.);
          }
          `,
  },
});

const InkInWater = ({ time }) => {
  return (
    <Node
      shader={shader.inkInWater}
      uniforms={{ u_time: time / 1000, u_resolution: [1920, 1920] }}
    ></Node>
  );
};

const InkInWaterLoop = timeLoop(InkInWater);

const InkInWater_HomePage_Alt = ({ width, height }) => {
  return (
    <>
      <Surface width={window.innerWidth} height={window.innerHeight}>
        <InkInWaterLoop />
      </Surface>
      {`${width}${height}`}
    </>
  );
};

export default withResizeDetector(InkInWater_HomePage_Alt);
