import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const OBE = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">
        Oldcastle Building Envelope AIA Booth
      </div>
      <img
        style={{ marginTop: "2%" }}
        className="projectPageImg"
        src={IMG("OBE_hero.jpg")}
      ></img>
      <div className="projectPageBody">
        For the 2017 AIA Conference, Oldcastle Building Envelope approached The
        LAB at Rockwell Group to create a hospitality experience unlike anything
        traditionally seen at the trade show. Rather than showcase company
        products, or provide a sales-pitch atmosphere, they wanted a place for
        conference attendees to relax, grab some refreshments, network, or check
        their email. Internally, we called it the anti-booth.
      </div>
      <img className="projectPageImg" src={IMG("OBE_interior1-min.jpg")}></img>
      <div className="projectPageBody">
        On a 50 x 50 plot, we designed a circular oasis with a variety of social
        spaces inside, from stadium-style seating to a more intimate space
        supplied with living room furniture. We also provided a creative room
        whereby guests could sketch on coasters and pin them up on the wall. The
        facade consisted aluminum louvers, vinyl-wrapped on a gradient from the
        client's brand white, to their brand green.
      </div>
      <img className="projectPageImg" src={IMG("OBE_interior2-min.jpg")}></img>
      <div className="projectPageBody">
        Over 1,000 people visited the booth throughout the three-day conference.
        The booth was awarded the 2017 Best Large Booth by AIA and continues to
        be used at various conferences nationwide.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default OBE;
