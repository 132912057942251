import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const FakeMirror = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Fake Mirror</div>
      <div className="projectPageBody">
        Fake Mirror is an interactive installation that asks: How does taking a
        photo of a work-of-art affect your experience of the piece?
      </div>
      <img className="projectPageImg" src={IMG("falseMirror.gif")}></img>
      <div className="projectPageBody">
        We created an installation that explores excessive photo-taking in
        museums to encourage conversation or reflection on these trends and
        their effects on the consumption of art.
      </div>
      <div className="projectPageBody">
        In its ambient mode, this installation displays Rene Magritte's famous
        1928 painting False Mirror, with the sky slowly moving in the
        background. We chose this painting because of its recognizability as an
        iconic piece of art and also its ability to build a relationship
        instantaneously with a viewer. The False Mirror is animate. It is
        staring at the viewer, acknowledging their presence, while seemingly
        benign.
      </div>
      <div className="projectPageBody">
        When someone approaches the piece and raises their phone to take a
        photo, the content suddenly changes to one of the six options above, one
        of which is a live webcam so that the viewer sees themself attempting to
        take a photo of, or selfie with, the installation. We chose these
        reactions because they felt surreal and intentionally are not what the
        photo-taker is trying to capture. They 'ruin' the photo. It is an art
        piece that reacts to the photo-takers disrespect with a defense
        mechanism, not allowing the picture to be taken.
      </div>
      <img
        style={{ marginBottom: "2%" }}
        className="projectPageImg"
        src={IMG("falsemirrorgrid.jpg")}
      ></img>
      <div className="player-wrapper-falsemirror">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/311580187"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <div className="projectPageBody">
        This project was made in collaboration with Kelsey Leuenberger, Nicole
        Lloyd, and Haley Mittelstaedt.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default FakeMirror;
