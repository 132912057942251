import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const MaskedContexts = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Masked Contexts</div>
      <div className="projectPageBody">
        <a
          className="aboutLink"
          target="_blank"
          href="https://maskedcontexts.com"
        >
          Masked Contexts
        </a>{" "}
        is an exploration into the COCO dataset and a dialogue with the
        photographers whose images were scraped by the dataset’s authors without
        their knowledge or consent. Email correspondence paired with the
        photographers' original images, the segmented masks and captions that
        they have been transformed into, and the photographers' possible
        reappropriations of their photos, all come together to present a
        multi-layered glimpse into the process of converting personal images
        into universal pieces of big data.
      </div>
      <img
        className="projectPageImg"
        src={IMG("maskedcontexts/grid.jpg")}
      ></img>
      <div className="projectPageBody">
        Originally published by Microsoft, the COCO (common objects in context)
        dataset contains 330,000 photos scraped from Flickr, many of which are
        intimate family photos uploaded by amateur photographers for personal
        use. Image datasets like COCO are often used to train surveillance
        technologies, amongst other types of computer vision programs. The
        dataset was reverse-engineered through custom scripts to provide
        additional metadata, including the Flickr username associated with each
        image. In 2019, I began contactinig Flickr users were contacted to
        inform them that their photos were part of the dataset. The
        conversations that emerged as a result are documented in this project
        and can be explored on the{" "}
        <a
          className="aboutLink"
          target="_blank"
          href="https://maskedcontexts.com"
        >
          project website.{" "}
        </a>
      </div>
      <img
        className="projectPageImg"
        src={IMG("maskedcontexts/frighteningPotential.png")}
      ></img>
      <div className="projectPageBody">
        The COCO metadata also provides annotations for each image in two forms,
        masks and captions. Both of these methods of annotation were made by
        hired Amazon Mechanical Turks. The masks outline particular object types
        found in each image, while the captions are meant to objectively
        describe each image. These captions are used for training computer
        vision programs so they can learn to analyze and describe visual inputs.
        There are five captions provided for each image. Masked Contexts
        showcases these annotations juxtaposed with the image authors’ response.
        The masks are additionally organized as a grid of seemingly
        incomprehensible visuals. When hovered over, they come into focus and
        their corresponding captions are read aloud.
      </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/414593742"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <div className="projectPageBody">
        Visit the{" "}
        <a
          className="aboutLink"
          target="_blank"
          href="https://maskedcontexts.com"
        >
          {" "}
          Masked Contexts website
        </a>{" "}
        to view the full experience.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default MaskedContexts;
