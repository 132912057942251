import React from "react";
import { NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const MainNav = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let margTop, fontSize;

  if (isBigScreen) {
    fontSize = "1.1rem";
  } else {
    fontSize = "0.9rem";
  }

  if (isMobileDevice) {
    margTop = "6%";
  } else {
    margTop = "2%";
  }

  const NavStyle = {
    marginTop: margTop,
    position: "sticky",
    marginLeft: "5%",
    fontFamily: "Courier",
    fontSize: fontSize,
    color: "black",
    display: "flex",
    zIndex: "99999",
  };

  return (
    <>
      <div className="name-logo">
        <a
          id="name"
          href="/"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          Noah Edelstein
        </a>{" "}
      </div>
      <div id="links" style={NavStyle}>
        <div>
          <NavLink
            exact
            className="linkStyle"
            activeClassName="navSelected"
            to="/"
            onClick={() => {
              document.getElementById("name").style.color = "inherit";
              document.getElementById("links").style.color = "black";
            }}
          >
            Work
          </NavLink>
        </div>
        <div>
          <NavLink
            className="linkStyle"
            activeClassName="navSelected"
            to="/about"
            onClick={() => {
              document.getElementById("name").style.color = "inherit";
              document.getElementById("links").style.color = "black";
            }}
          >
            About
          </NavLink>
        </div>
        <div>
          <NavLink
            className="linkStyle"
            activeClassName="navSelected"
            to="/sketches"
            onClick={() => {
              document.getElementById("name").style.color = "inherit";
              document.getElementById("links").style.color = "black";
            }}
          >
            Sketches
          </NavLink>
        </div>
      </div>
      <hr
        style={{
          marginLeft: "5%",
          marginRight: "4%",
          marginTop: "1%",
          position: "sticky",
          zIndex: "99",
        }}
      ></hr>
    </>
  );
};

export default MainNav;
