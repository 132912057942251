import React from "react";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const MFADTSite = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Hindsight Thesis Website</div>
      <img
        style={{ marginTop: "3%" }}
        className="projectPageImg"
        src={IMG("hindsightHero.png")}
      ></img>
      <div className="projectPageBody">
        I was the chair of the committee that conceptualized and designed{" "}
        <a className="aboutLink" target="_blank" href="https://parsons.edu/dt">
          HINDSIGHT,
        </a>{" "}
        an online exhibit and festival for the BFA/MFA Design & Technology
        graduating class of 2020 at the Parsons School of Design.
      </div>
      <img className="projectPageImg" src={IMG("hindsight_alt.jpg")}></img>
      <div className="projectPageBody">
        Due to the COVID-19 Pandemic, the Design & Technology class of 2020 had
        to transition their end-of-year thesis showcase to an entirely virtual
        event. We designed an interactive exhibit where students could showcase
        their work, live-stream lectures, and publish writing.
      </div>
      <div className="projectPageBody">
        The website features an animating background gradient that changes on a
        page reload, as well as a digital 'guestbook' that anonymously tracks
        visitor movement to document site activity.
      </div>
      <img className="projectPageImg" src={IMG("hindsight_students.png")}></img>
      <div className="projectPageBody">
        This project was made in collaboration with June Lee, Jon Packles,
        Kalyani Tupkary, Lan Zhang, Anna Garbier, Dylan Fisher, Richard The, and
        more.
      </div>
      <div className="projectPageBody">
        Visit the{" "}
        <a className="aboutLink" target="_blank" href="https://parsons.edu/dt">
          HINDSIGHT website
        </a>{" "}
        to view the full experience.
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default MFADTSite;
