import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import projectsLookup from "../projects_lookup";

const IMG = (imgName) => {
  return require(`../assets/images/homepage_imgs/${imgName}`);
};

let projects = [];
let projectNames = [];
let projectTitle = [];
let projectSkills = [];
let projectLocked = [];
for (let i in projectsLookup) {
  projects.push(projectsLookup[i].image);
  projectNames.push(projectsLookup[i].project);
  projectTitle.push(projectsLookup[i].title);
  projectSkills.push(projectsLookup[i].skills);
  projectLocked.push(projectsLookup[i].locked);
}

const ProjectsGrid = () => {
  const [title, setTitle] = useState(null);
  const [skills, setSkills] = useState(null);

  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let imgWidth, imgMargTop;
  if (isMobileDevice) {
    imgWidth = "95%";
    imgMargTop = "8%";
    // } else if (isBigScreen) {
    //   imgWidth = "30%";
    //   imgMargTop = "2%";
  } else {
    imgWidth = "30%";
    imgMargTop = "2%";
  }

  const imgStyle = {
    display: "inline",
    width: imgWidth,
    height: "auto",
    marginRight: "3%",
    marginTop: imgMargTop,
    // zIndex: "9999999",
    cursor: "help",
    // boxShadow: "0 6px 3px -3px #777",
    boxShadow:
      "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12)",
  };

  const imgContainer = {
    display: "inline",
    position: "relative",
    // textAlign: "center",
  };

  const fadeInStyle = {
    display: "inline",
  };

  return (
    <>
      {projects.map((image, index) => (
        <span style={imgContainer} id={index}>
          <Link
            style={{ display: "inline !important" }}
            to={projectNames[index]}
          >
            <img
              style={imgStyle}
              className="projectImg"
              src={IMG(projects[index])}
              alt="nope"
              onMouseEnter={() => {
                setTitle(projectTitle[index]);
                setSkills(projectSkills[index]);
                document.getElementById(projects[index]).style.opacity = "1";
              }}
              onMouseLeave={() => {
                setTitle(null);
                setSkills(null);
                document.getElementById(projects[index]).style.opacity = "0";
              }}
              onClick={() => {
                document.getElementById("name").style.color = "inherit";
                document.getElementById("links").style.color = "black";
              }}
            ></img>
            <div id={projects[index]} className="projTitle">
              {title}
              <div style={{ marginTop: "2%", fontSize: "0.8rem" }}>
                {skills}
              </div>
            </div>
          </Link>
        </span>
      ))}
    </>
  );
};

export default ProjectsGrid;
