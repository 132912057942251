import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const MOF = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">Museum of Feelings</div>
      <div className="projectPageBody">
        The Museum of Feelings was an experiential pop-up space with five
        separate galleries. A partnership with Glade, each gallery had a themed
        emotion with a corresponding scent. The design brief from the client
        asked us to create a memorable experience and attach it to a scent so
        that customers could associate the memory to the smell.
      </div>
      <img className="projectPageImg" src={IMG("MOF_exterior.jpg")}></img>
      <div className="projectPageBody">
        For the exhibit, the LAB at Rockwell Group designed the architecture and
        floorplan, as well as one of the five immersive experiences, the
        Exhilarated Room. The Exhilarated room was a mirror-cladded space that
        imitated the inside of a kaleidoscope. Gently rotating flower animations
        were rear-projected from above the room. Guests could manipulate the
        animations at three kiosks in the center of the space. The interaction
        made it feel like you were controlling the inhabited kaleidescope.
      </div>
      <img
        style={{ marginBottom: "2%" }}
        className="projectPageImg"
        src={IMG("MOF_interior1.jpg")}
      ></img>
      <div className="player-wrapper-collectiveUnbelonging">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/308038941"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default MOF;
