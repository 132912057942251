import React from "react";

const IMG = (imgName) => {
    return require(`../assets/images/${imgName}`);
  };

  const Runway360= () => {
    return (
        <div className="page">
            <div className="projectPageTitle">CFDA Runway360</div>
        
            <div className="projectPageBody">
                RUNWAY360 was an immersive digital platform built for the CFDA to support American designers by 
                bringing together every aspect of a collection launch in a one-stop-shop environment.
                <br/><br/>
                The website incorporated key aspects of the designer business, from show to press, 
                sales, and consumer activations, to help drive sales while providing the industry 
                with a dynamic tool to experience the best of American fashion creativity.
            </div>
            <img className="projectPageImg"
            alt="Runway360 Hero"
            src={IMG("runway360/runway_hero.jpeg")}></img>
            <div className="projectPageBody">

                Designers were able to present their collections, providing each participating brand 
                with a modular, customizable page for their storytelling and digital activations. The hub allowed designers to host virtual press conferences, as well as auto-scheduled live streams
                of shows during NYFW.
            </div>
            <img className="projectPageImg"
            alt="Runway360 Schedule"
            src={IMG("runway360/runway_schedule.jpg")}></img>
            <br/><br/>
            <img className="projectPageImg"
            alt="Runway360 Lookbook"
            src={IMG("runway360/runway_lookbook.jpg")}></img>
            <br/><br/>
            <img className="projectPageImg"
            alt="Runway360 Mobile"
            src={IMG("runway360/runway_mobile.jpg")}></img>
            <br/><br/>
            <img className="projectPageImg"
            alt="Runway360 Designers"
            src={IMG("runway360/runway_designers.jpg")}></img>
            <br/><br/>
        </div>
    )
  };

export default Runway360;
