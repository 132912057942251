import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const ParticleSystemLetters = () => {
  return (
    <div className="page">
      {" "}
      <div className="projectPageTitle">Particle System Alphabet</div>
      <div className="projectPageBody">
        Particle System Alphabet is an interactive desktop application that
        meditatively draws the letters you press on your keyboard using particle
        systems and simulated elastic and gravitational forces.
      </div>{" "}
      <div className="projectPageBody">
        Any character or font can be implemented into this application.
      </div>
      <div
        className="player-wrapper-collectiveUnbelonging"
        style={{ marginBottom: "3%" }}
      >
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/307363143"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <CopyrightFooter />
    </div>
  );
};

export default ParticleSystemLetters;
