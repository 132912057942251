import React from "react";
import ReactPlayer from "react-player";
import CopyrightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/${imgName}`);
};

const altImg = {
  transform: "translate(-50%, 0%)",
  marginLeft: "50%",
  marginBottom: "3%",
  marginTop: "2%",
};

const MGMCotai = () => {
  return (
    <div className="page">
      <div className="projectPageTitle">MGM Cotai Spectacle</div>
      <div className="projectPageBody">
        The Spectacle is a football-field-sized, glass-topped atrium at the
        heart of the MGM Cotai property in Macau, and at the confluence of its
        many offerings.
      </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/299469014"
          width="100%"
          height="100%"
          controls="true"
        />
      </div>
      <div className="projectPageBody">
        The LAB at Rockwell Group designed the 25 high definition LED screens in
        the Spectacle and they blur the lines between physical architecture and
        digital activation. A "cloaked" state camouflages the screens into its
        surroundings with digital representations of the physical space.
        Throughout the day the content changes, bringing the space to life with
        moments of surprise and wonder.
      </div>
      <img className="projectPageImg" src={IMG("mgm_altHero.jpg")}></img>
      <img
        style={{ marginTop: "2%" }}
        className="projectPageImg"
        src={IMG("mgm_hero.jpg")}
      ></img>
      <img
        style={{ marginTop: "2%" }}
        className="projectPageImg"
        src={IMG("mgm_interior3.jpg")}
      ></img>
      <CopyrightFooter />
    </div>
  );
};

export default MGMCotai;
